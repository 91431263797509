import React, { Component } from 'react'
import * as emailjs from 'emailjs-com'
import { Button, FormFeedback, Form, FormGroup, Label, Input, Container, Row, Col,  InputGroupAddon, InputGroupText, InputGroup,} from 'reactstrap'
class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
  }
handleSubmit(e) {
    e.preventDefault()
    const { name, email, subject, message } = this.state
    let templateParams = {
      from_name: email,
      to_name: 'sebastianwidmaier@web.de',
      subject: subject,
      message_html: message,
     }
     emailjs.send(
      'gmail',
      'template_0bvBZX5t',
      templateParams,
      'user_tEiFlJmXPtl0EGnaboYkV'
     )
     this.resetForm()
 }
resetForm() {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: '',
    })
  }
handleChange = (param, e) => {
    this.setState({ [param]: e.target.value })
  }
render() {
    return (
      <>
        <div className="section section-contact-us text-center">
          <Container>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
          <Form onSubmit={this.handleSubmit.bind(this)}>
            <FormGroup controlId="formBasicEmail">
              
              <Input
                type="email"
                name="email"
                value={this.state.email}
                className="text-primary"
                onChange={this.handleChange.bind(this, 'email')}
                placeholder="Deine E-Mail Adresse"
              />
            </FormGroup>
<FormGroup controlId="formBasicMessage">
              
              <Input
               cols="80"
                    name="name"
                    placeholder="Wie können wir dir weiterhelfen?"
                    rows="4"
                    type="textarea"
                value={this.state.message}
                onChange={this.handleChange.bind(this, 'message')}
              />
            </FormGroup>
<Button 
block
                    className="btn-round"
					size="lg"
                    color="info" type="submit">
              Nachricht senden
            </Button>
          </Form>
        </Col>
            </Row>
          </Container>
        </div>
      </>
    )
  }
}
export default ContactForm