import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

// core components

function Impressum() {
  return (
    <>
                     <div className="section-story-overview">
					 <Container>
			
              <Row>

                <Col md="12">
                  <h2>
                    Angaben gemäß § 5 TMG:
                  </h2>
                  <h3>
                    Anschrift:
                  </h3>
<h5>
                    DPSG Haigerloch Stamm Leonardo Da Vinci <br/>
                    Baumäckerweg 1 <br/>
                    72401 Haigerloch <br/><br/>
</h5>
                  <h3>
                    Kontakt:
                  </h3>
<h5>
                    E-Mail: info@pfadis-haigerloch.de <br/><br/>
</h5>
                  <h2>
                    Haftungsausschluss::
                  </h2>
                  <h3>
                    Haftung für Inhalte:
                  </h3>
                  <p>
                    Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                  </p>
				                    <h3>
                    Haftung für Links:
                  </h3>
                  <p>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                  </p>
				  				                    <h3>
                    Urheberrecht:
                  </h3>
                  <p>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                  </p>
				  <h2>
                    Datenschutz:
                  </h2>
				  <h3>
                    1. Datenschutzerklärung:
                  </h3>
				   <p>
                    Diese Datenschutzerklärung unterrichtet dich gemäß Art. 13 DS-GVO analog dazu § 15 KDG über Art und Weise und die Hintergründe der Verarbeitung deiner personenbezogenen Daten über die Website www.dpsg.de (nachfolgend „ Website“) und über die euch zustehenden Rechte.

Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen. Als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind. Dieses sind also nicht nur dein Name, deine E-Mail-Adresse oder ‚deine Kontaktdaten, sondern z.B. auch die IP-Adresse, über die du ggf. rein informatorisch unsere Website besuchst.
                  </p>
				  				  <h3>
                   2. Verantwortliche/r
                  </h3>
				   <p>
                    Verantwortliche gemäß Art. 4 Nr. 7 DS-GVO bzw. § 4 Nr. 9 KDG ist:<br/>
					               DPSG Haigerloch Stamm Leonardo Da Vinci <br/>
                    Baumäckerweg 1 <br/>
                    72401 Haigerloch <br/><br/>
					Vertretungsberechtigt ist der Vereinsvorstand: Michael Kuner & Volker Schmid
                  </p>
				  				  				  <h3>
                   3. Datenschutzbeauftragte/r
                  </h3>
				   <p>
                   Die Kontaktdaten der/des Datenschutzbeauftragte/n lauten: info@pfadis-haigerloch.de
                  </p>
				  				  				  				  <h3>
                   4. Eure Betroffenenrechte
                  </h3>
				   <p>
                   Ihr habt nach der DS-GVO und dem KDG (§§ 17 ff. KDG) folgende Rechte hinsichtlich der euch betreffenden personenbezogenen Daten:
<br/>
- Recht auf Auskunft,
<br/>
- Recht auf Berichtigung,
<br/>
- Recht auf Löschung,
<br/>
- Recht auf Einschränkung der Verarbeitung,
<br/>
- Recht auf Widerspruch gegen die Verarbeitung,
<br/>
- Recht auf Datenübertragbarkeit.
<br/>
Sie haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde bzw. der Datenschutzaufsicht (§§ 42 ff. KDG) über die Verarbeitung Ihrer personenbezogenen Daten zu beschweren.
                  </p>
				  				  				  				  				  <h3>
                   5. Kontaktanfragen
                  </h3>
				   <p>
                  Bei Anfragen über unser Kontaktformular oder im Rahmen unserer dir zur Verfügung gestellten E-Mailadresse verarbeiten wir die von dir freiwillig zur Verfügung gestellten personenbezogenen Daten ausschließlich dazu, um deine Anfrage zu bearbeiten, zu beantworten oder uns mit dir in Verbindung zu setzen. Die in diesem Zusammenhang anfallenden Daten werden nach abschließender Beantwortung gelöscht, sollten nicht gesetzliche oder sonstige Aufbewahrungspflichten bestehen. Rechtsgrundlage für diese Verarbeitung Ihrer personenbezogenen Daten ist Art. 6 Abs. 1 lit. b DS-GVO bzw. § 6 Abs. 1 lit. c KDG.
                  </p>
				  <h3>
                   6. Links zu anderen Websites
                  </h3>
				   <p>
                  Die Website enthält ggf. Links zu anderen Websites, zu Datenschutzerklärungen und Datenschutzrichtlinien auf diesen Websites. Wir übernehmen weder eine Haftung noch eine Verantwortung für diese nicht im Zusammenhang mit unserer Website stehenden Erklärungen und Richtlinien.
                  </p>
				  <h3>
                   7. Rein informatorischer Besuch unserer Website
                  </h3>
				   <p>
                 Bei dem informatorischen Besuch unserer Website erfassen wir automatisiert folgende Daten und Informationen vom Computersystem des aufrufenden Rechners:
<br/>
- IP-Adresse
<br/>
- Datum und Uhrzeit der Anfrage
<br/>
- Zeitzonendifferenz zur Greenwich Mean Time (GMT)
<br/>
- Inhalt der Anforderung (konkrete Seite)
<br/>
- Zugriffsstatus/HTTP-Statuscode
<br/>
- jeweils übertragene Datenmenge
<br/>
- Website, von der die Anforderung kommt
<br/>
- Browser
<br/>
- Betriebssystem und dessen Oberfläche
<br/>
- Sprache und Version der Browsersoftware
<br/>
Die Daten werden ebenfalls in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser Daten zusammen mit anderen personenbezogenen Daten des Nutzers findet nicht statt.
<br/>
Diese Datenverarbeitung durch unser System dient dem Zweck und unserem Interesse, die Auslieferung der Website an den Rechner des Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für die Dauer der Sitzung gespeichert bleiben. Die Speicherung in Logfiles erfolgt zu dem Zweck und unserem Interesse, die Funktionsfähigkeit der Website sicherzustellen und zur Optimierung der Website sowie zur Sicherstellung der Sicherheit unserer informationstechnischen Systeme. Eine Auswertung dieser Daten zu Marketingzwecken findet nicht statt.
<br/>
Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO bzw. § 6 Abs. 1 lit. g KDG. Die Daten werden in unserem System gelöscht, wenn die jeweilige Sitzung beendet ist. Die in den Logfiles gespeicherten Daten werden nach spätestens sieben Tagen gelöscht oder anonymisiert, sodass eine Zuordnung des aufrufenden Clients nicht mehr möglich ist. Die Erfassung der Daten zur Bereitstellung der Website und die Speicherung der Daten in Logfiles ist für den Betrieb der Internetseite zwingend erforderlich. Im Übrigen erheben wir personenbezogene Daten von dir nur dann, wenn du uns diese auf der Website freiwillig zur Verfügung stellst, z. B., wenn du ein Benutzerkonto einrichten oder dich bei einem Newsletter registrieren, eine Bestellung aufgeben oder mit uns in Kontakt treten möchtest.
<br/>
Du kannst die Website auch generell besuchen, ohne personenbezogene Daten preiszugeben. In diesem Fall kann es jedoch sein, dass ihr bestimmte Bereiche der Website nicht nutzen bzw. wir eine Anfrage von euch nicht beantworten können.
                  </p>
				   <h3>
                   8. Was sind Cookies?
                  </h3>
				   <p>
                  Ein Cookie ist ein kleiner Datensatz, der auf Ihrem Endgerät gespeichert wird und Daten wie z. B. persönliche Seiteneinstellungen und Anmeldeinformationen enthält. Dieser Datensatz wird von dem Webserver, mit dem du über deinen Webbrowser eine Verbindung aufgebaut hast, erzeugt und an dich gesendet.
<br/>
Nachfolgend sind die gebräuchlichsten Arten von Cookies zu deinem Verständnis erklärt:<br/>
<br/>
- Sitzungs-Cookies: Während du auf einer Webseite aktiv bist, wird temporär ein Sitzungs-Cookie im Speicher deines Computers abgelegt, in dem eine Sitzungskennung gespeichert wird, um z. B. bei jedem Seitenwechsel zu verhindern, dass du dich erneut anmelden musst. Sitzungs-Cookies werden bei der Abmeldung gelöscht oder verlieren ihre Gültigkeit, sobald ihre Sitzung automatisch abgelaufen ist.<br/>
<br/>
- Permanente oder Protokoll-Cookies: Ein permanenter oder Protokoll-Cookie speichert eine Datei über den im Ablaufdatum vorgesehenen Zeitraum auf deinem Computer. Durch diese Cookies erinnern sich Websites bei deinem nächsten Besuch an deine Informationen und Einstellungen. Das führt zu einem schnelleren und bequemeren Zugriff, da du z. B. nicht erneut die Spracheinstellung vornehmen musst. Mit Verstreichen des Ablaufdatums wird der Cookie automatisch gelöscht, wenn du die Webseite besuchst, die diesen erzeugt hat.
<br/><br/>
Drittanbieter-Cookies: Drittanbieter-Cookies stammen von anderen Anbietern als dem Betreiber der Webseite. Sie können beispielsweise zum Sammeln von Informationen für Werbung, benutzerdefinierte Inhalte und Web-Statistiken verwendet werden.
                  </p>
				  				   <h3>
                   9. Technisch notwendige Cookies
                  </h3>
				   <p>
                  Einige Elemente unserer Internetseite erfordern es, dass der aufrufende Browser auch nach einem Seitenwechsel identifiziert werden kann. In den bei euch zu setzenden Cookies werden dabei folgende Daten gespeichert und übermittelt:<br/>
<br/>
- Spracheinstellungen<br/>
<br/>
- Artikel in einem Warenkorb<br/>
<br/>
- Log-In-Informationen<br/>
<br/>
Die Cookies werden bei Schließung des Browsers gelöscht. Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung dieser technisch notwendigen Cookies ist Art. 6 Abs. 1 lit. f DSGVO bzw. § 6 Abs. 1 lit. g KDG. Die Cookies dienen unserem berechtigten Interesse, die Nutzung und Bereitstellung unserer Website zu ermöglichen. Bei den Cookies handelt es sich um für die Nutzung der Website technisch notwendige Cookies.
                  </p>
				  				  				   <h3>
                   10. Nutzerprofile
                  </h3>
				   <p>
                 Auf unserer Website verwenden wir über die technisch notwendigen Cookies hinaus auch Cookies, die Ihr Nutzerverhalten analysieren können. Hier geht es also darum, wie ihr unsere Website im Einzelnen verwenden. Wir können damit die Qualität unserer Website und ihre Inhalte verbessern. Auf diese Weise können folgende (personenbezogene) Daten übermittelt werden:<br/>
<br/>
- Eingegebene Suchbegriffe<br/>
<br/>
- Häufigkeit von Seitenaufrufen<br/>
<br/>
- Inanspruchnahme von Website-Funktionen<br/>
<br/>
Die auf diese Weise erhobenen Daten werden durch technische Vorkehrungen pseudonymisiert. Daher ist eine Zuordnung der Daten zum aufrufenden Nutzer nicht mehr möglich. Die Daten werden nicht gemeinsam mit sonstigen personenbezogenen Daten der Nutzer gespeichert.<br/>
<br/>
Du kannst unsere Website grundsätzlich auch ohne Cookies aufrufen. Willst du unsere Websites vollumfänglich bzw. komfortabel nutzen, solltest du jedoch diejenigen Cookies akzeptieren, die die Nutzung bestimmter Funktionen ermöglichen bzw. die Nutzung komfortabler machen.<br/>
<br/>
Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten unter Verwendung der Cookies zu Analysezwecken ist die von euch erteilte Einwilligungserklärung gem. Art. 6 Abs. 1 lit. a DS-GVO bzw. § 6 Abs. 1 lit. b KDG. Ihr könnt eure Einwilligung jederzeit widerrufen; die personenbezogenen Daten werden dann unverzüglich gelöscht. Ihr habt als Nutzerin / Nutzer die volle Kontrolle über die Speicherung der Cookies und könnt gespeicherte Cookies etwa in eurem Browser jederzeit löschen.
                  </p>
				  				  				  				   <h3>
                   11. Externe Plugins
                  </h3>
				   <p>
                 Um für ausreichend Datensicherheit bei der Übermittlung von Formularen Sorge zu tragen, verwenden wir in bestimmten Fällen den Service reCAPTCHA des Unternehmens Google Inc. Dies dient vor allem zur Unterscheidung, ob die Eingabe durch einen Mensch oder missbräuchlich durch automatisierte Verarbeitung erfolgte. Der Service nutzt die IP-Adresse und ggf. weiterer von Google für den Dienst reCAPTCHA benötigter Daten an Google ein. Hierfür gelten die Datenschutzbestimmungen von Google Inc. Weitere Informationen zu den Datenschutzrichtlinien von Google Inc. finden Sie unter http://www.google.de/intl/de<br/>
<br/>
Für die Stammessuche verwenden wir den Google Dienst Maps. Zur Darstellung von DPSG-Stämmen in Deutschland übertragen wir Kontaktdaten an Google. Weitere Informationen zu den Datenschutzrichtlinien von Google Inc. finden Sie unter http://www.google.de/intl/de<br/>
<br/>
Für einige Formulare wird die quelloffene Software LimeSurvey verwendet. Das Programm läuft auf demselben Webserver, wie die gesamte Webseite. Es werden keine Daten an Dritte übertragen.
                  </p>
                </Col>
              </Row>
			  </Container>
            </div>
    </>
  );
}

export default Impressum;
