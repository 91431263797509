import React, { Component } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageUploader from 'react-images-upload';
import { Button,Form, Progress, FormGroup, Label, Input, Container, Row, Col, UncontrolledTooltip } from "reactstrap";
import ReactPlayer from 'react-player'
import Rückschau_2019 from 'assets/video/Rückschau 2019_comp.mp4'
import Rückschau_2017 from 'assets/video/Rückschau 2017_comp.mp4'
import { Link } from "react-router-dom";


class App extends Component {
  constructor(props) {
    super(props);
      this.state = {
        selectedFile: null,
        loaded:0
      }
   
  }
  checkMimeType=(event)=>{
    //getting file object
    let files = event.target.files 
    //define message container
    let err = []
    // list allow mime type
   const types = ['image/png', 'image/jpeg', 'image/gif', 'application/x-gzip']
    // loop access array
    for(var x = 0; x<files.length; x++) {
     // compare file type find doesn't matach
         if (types.every(type => files[x].type !== type)) {
         // create error message and assign to container   
         err[x] = files[x].type+' is not a supported format\n';
       }
     };
     for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
         // discard selected file
        toast.error(err[z])
        event.target.value = null
    }
   return true;
  }
  maxSelectFile=(event)=>{
    let files = event.target.files
        if (files.length > 100) { 
           const msg = 'Only 100 images can be uploaded at a time'
           event.target.value = null
           toast.warn(msg)
           return false;
      }
    return true;
 }
 checkFileSize=(event)=>{
  let files = event.target.files
  let size = 2000000 
  let err = []; 
  for(var x = 0; x<files.length; x++) {
  if (files[x].size > size) {
   err[x] = files[x].type+'is too large, please pick a smaller file\n';
 }
};
for(var z = 0; z<err.length; z++) {// if message not same old that mean has error 
  // discard selected file
 toast.error(err[z])
 event.target.value = null
}
return true;
}
onChangeHandler=event=>{
  var files = event.target.files
  if(this.maxSelectFile(event) && this.checkMimeType(event) &&    this.checkFileSize(event)){ 
  // if return true allow to setState
     this.setState({
     selectedFile: files,
     loaded:0
  })
}
}
  onClickHandler = () => {
    const data = new FormData() 
    for(var x = 0; x<this.state.selectedFile.length; x++) {
      data.append('file', this.state.selectedFile[x])
    }
    axios.post("/upload", data, {
      onUploadProgress: ProgressEvent => {
        this.setState({
          loaded: (ProgressEvent.loaded / ProgressEvent.total*100),
        })
      },
    })
      .then(res => { // then print response status
        toast.success('upload success')
      })
      .catch(err => { // then print response status
        toast.error('upload fail' + err )
      })
    }

  render() {
    return (
	   <>
         <div className="section section-team text-center">
          <Container>
            <h2 className="title">Bilder im Jahrespaket downloaden</h2>
            <div className="team">
              <Row>
			  	<Col md="3">
                  <div className="team-player">
				  <a href="#" target="_blank">
                    <img  alt="..." className="rounded-circle img-fluid img-raised" src={require("assets/img/Bilder_2016.jpg")}></img>
                    <h4 className="title">2016</h4>
                    <p ><b>Alle Bilder aus dem Jahr 2016</b></p>
					</a>
                  </div>
                </Col>
			  	<Col md="3">
                  <div className="team-player">
				  <a href="#" target="_blank">
                    <img  alt="..." className="rounded-circle img-fluid img-raised" src={require("assets/img/Bilder_2017.jpg")}></img>
                    <h4 className="title">2017</h4>
                    <p ><b>Alle Bilder aus dem Jahr 2017</b></p>
					</a>
                  </div>
                </Col>
			  	<Col md="3">
                  <div className="team-player">
				  <a href="#" target="_blank">
                    <img  alt="..." className="rounded-circle img-fluid img-raised" src={require("assets/img/Bilder_2018.jpg")}></img>
                    <h4 className="title">2018</h4>
                    <p ><b>Alle Bilder aus dem Jahr 2018</b></p>
					</a>
                  </div>
                </Col>
			  	<Col md="3">
                  <div className="team-player">
				  <a href="#" target="_blank">
                    <img  alt="..." className="rounded-circle img-fluid img-raised" src={require("assets/img/Bilder_2019.jpg")}></img>
                    <h4 className="title">2019</h4>
                    <p ><b>Alle Bilder aus dem Jahr 2019</b></p>
					</a>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
		  <Container>
            <h2 className="title">Filme zum anschauen</h2>
              <Row>
				<Col md="6">
				<h4 className="title">Rückblick 2019</h4>
					<div style={{height: 300 }} className='player-wrapper'>
						<ReactPlayer
						  className='react-player'
						  url={Rückschau_2019} tag={Link}
						  controls = {true}
						  width='100%'
						  height='100%'
						/>
					</div>
                </Col>
				<Col md="6">
				<h4 className="title">Rückblick 2017</h4>
					<div style={{height: 300 }} className='player-wrapper'>
						<ReactPlayer
						  className='react-player'
						  url={Rückschau_2017}
						  controls = {true}
						  width='100%'
						  height='100%'
						/>
					</div>
                </Col>
              </Row>
          </Container>
        </div>

	
      <div class="container">        
			<input type="file" class="form-control" multiple onChange={this.onChangeHandler}/>
			<div class="form-group">
              <ToastContainer />
              <Progress max="100" color="success" value={this.state.loaded} >{Math.round(this.state.loaded,2) }%</Progress>
             </div> 
			 <button type="button" class="btn btn-success btn-block" onClick={this.onClickHandler}>Upload</button>
      </div>
	      </>
    );
  }
}

export default App;
