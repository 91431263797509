// src/components/Profile.js

import React, { Fragment } from "react";
import { useAuth0 } from "../react-auth0-spa";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Einstellungen from "./Headers/Einstellungen.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { Router, Route, Switch } from "react-router-dom";
import Startseite from "views/examples/Startseite";
import history from "utils/history";
import PrivateRoute from "components/PrivateRoute";

import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// sections for this page
import Images from "views/index-sections/Images.js";

import BasicElements from "views/index-sections/BasicElements.js";
import Navbars from "views/index-sections/Navbars.js";
import Tabs from "views/index-sections/Tabs.js";
import Pagination from "views/index-sections/Pagination.js";
import Notifications from "views/index-sections/Notifications.js";
import Typography from "views/index-sections/Typography.js";
import Javascript from "views/index-sections/Javascript.js";
import Carousel from "views/index-sections/Carousel.js";
import NucleoIcons from "views/index-sections/NucleoIcons.js";
import CompleteExamples from "views/index-sections/CompleteExamples.js";
import SignUp from "views/index-sections/SignUp.js";
import Examples from "views/index-sections/Examples.js";
import Download from "views/index-sections/Download.js";
import ReactContactForm from "views/index-sections/ContactUs.js";


const Profile = () => {
React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  
  var request = require("request");
	
  const { loading, user } = useAuth0();

  if (loading || !user) {
    return <div>Loading...</div>;
  }
  
  return (

    <Fragment>
		<Einstellungen />
		<div className="section section-about-us">
			<Container>
				<Row>
					<Col md="12">
						<img src={user.picture} alt="Profile" />
					</Col>
					<Col md="12">
						<h2 className="title">Deine Nutzerdaten:</h2>
					</Col>
					<Col md="3">
						<p>Deine Name:</p>
					</Col>
					<Col md="9">
						<p>{user.nickname}</p>
					</Col>
					<Col md="3">
						<p>Deine E-Mail Adresse:</p>
					</Col>
					<Col md="9">
						<p>{user.email}</p>
					</Col>
					<Col md="3">
						<p>Letzte Änderung:</p>
					</Col>
					<Col md="9">
						<p>{user.updated_at}</p>
					</Col>
					<Col md="3">
						<p>Dein Token:</p>
					</Col>
					<Col md="9">
						<p>{user.sub}</p>
					</Col>
				</Row>
				<h2 className="title">Melde dich für Änderungen</h2>
				 <ReactContactForm to="info@pfadis-haigerloch.de" />
				 
				 
			</Container>
		</div>
    </Fragment>
  );
};

export default Profile;