/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
		  <li>
				              <Button
                className="btn-round"
                data-background-color="black"
                to="/Impressum"
         
                tag={Link}
              >
                Impressum
              </Button>
            </li>
            <li>
              <a
                href="https://de.wikipedia.org/wiki/Deutsche_Pfadfinderschaft_Sankt_Georg"
                target="_blank"
              >
                Wikipedia
              </a>
            </li>
            <li>
              <a
                href="https://dpsg.de/de/startseite.html"
                target="_blank"
              >
                DPSG
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, Designed by{" "}
          <a
            href="https://www.invisionapp.com?ref=nukr-dark-footer"
            target="_blank"
          >
            Invision
          </a>
          . Coded by{" "}
          <a
            href="https://www.creative-tim.com?ref=nukr-dark-footer"
            target="_blank"
          >
            Creative Tim
          </a>
          .
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
