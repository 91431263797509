import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

// core components

function Download() {
  return (
    <>
         <div className="section section-team text-center">
          <Container>
            <h2 className="title">Stamm und e.V. Unterlagen</h2>
            <div className="team">
              <Row>
			  	<Col md="3">
                  <div className="team-player">
				  <a href="https://Pfadis-Haigerloch.de/Downloads/Anmeldebogen_Stamm_2020.pdf" target="_blank">
                    <img  alt="..." className="rounded-circle img-fluid img-raised" src={require("assets/img/Lilie.jpg")}></img>
                    <h4 className="title">Anmeldung Stamm</h4>
                    <p ><b>Hier findest du die Anmeldung für den Stamm Leonoard Da Vinci Haigerloch.</b></p>
					</a>
                  </div>
                </Col>
                <Col md="3">
                  <div className="team-player">
				  <a href="https://Pfadis-Haigerloch.de/Downloads/Anmeldebogen_eV_2020.pdf" target="_blank">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/Mitglied.jpg")}
                    ></img>
                    <h4 className="title">Anmeldung e.V.</h4>
                    <p><b>Hier findest du die Anmeldung für den e.V. welcher den Stamm untersützt</b></p>
					</a>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
		  <Container>
            <h2 className="title">Alles für das Hüttenwochenende</h2>
            <div className="team">
              <Row>
			  	<Col md="3">
                  <div className="team-player">
				  <a href="#" target="_blank">
                    <img  alt="..." className="rounded-circle img-fluid img-raised" src={require("assets/img/HueWo.jpg")}></img>
                    <h4 className="title">Anmeldung Hüwo</h4>
                    <p><b>Downloade dir die Anmeldung für das Hüttenwochenende.</b></p>
					</a>
                  </div>
                </Col>
                <Col md="3">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/Packliste.jpg")}
                    ></img>
                    <h4 className="title">Packliste Hüwo</h4>
                    <p><b>Downloade dir die Packliste für das Hüttenwochenende.</b></p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
    </>
  );
}

export default Download;
