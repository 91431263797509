import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  UncontrolledTooltip
} from "reactstrap";

// core components

const items = [
  {
    src: require("assets/img/Moritz.jpg"),
    caption: "Moritz Reccius, Vorstand & Rover-Leiter"
  },
  {
    src: require("assets/img/Jan-Aaron.jpg"),
	caption: "Jan-Aaron Pfeffer, Vorstand & Pfadfinder-Leiter"
  },
  {
    src: require("assets/img/Anki.jpg"),
	caption: "Ann-Kathrin Pfeffer, Wölflingsleiterin"
  },
  {
    src: require("assets/img/Benny.jpg"),
	caption: "Benjamin Kizilcelik, Rover-Leiter"
  },
  {
    src: require("assets/img/Ina.jpg"),
	caption: "Katharina Stocker, Leiterin"
  },
  {
    src: require("assets/img/Isa.jpg"),
	caption: "Isabelle Fischer, Leiterin"
  },
  {
    src: require("assets/img/Jan.jpg"),
	caption: "Jan Timmler, Pfadfinder-Leiter"
  },
  {
    src: require("assets/img/Julia.jpg"),
	caption: "Julia Streib-Lehmann, Wölflingsleiterin"
  },
  {
    src: require("assets/img/Matze.jpg"),
	caption: "Matthias Helmke, Leiter"
  },
  {
    src: require("assets/img/Monja.jpg"),
	caption: "Monja Jaumann, Leiter"
  },
  {
    src: require("assets/img/Moritz2.jpg"),
	caption: "Moritz Haug, Leiter"
  },
  {
    src: require("assets/img/Schmokker.jpg"),
	caption: "Julian Schmocker, Leiter"
  },
  {
    src: require("assets/img/Silvana.jpg"),
	caption: "Silvana Ströbele, Küchenteam"
  },
  {
    src: require("assets/img/Timo.jpg"),
	caption: "Timo Jaumann, Leiter"
  }
];

function CarouselSection() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
	<div className="section section-nucleo-icons">
        <Container>
      <div className="section" id="carousel">
        <Container>
          <Row className="justify-content-center">
            <Col lg="0" md="8">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                <CarouselIndicators
                  items={items}
                  activeIndex={activeIndex}
                  onClickHandler={goToIndex}
                />
                {items.map(item => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                    >
                      <img src={item.src} alt={item.altText} />
                      <div className="carousel-caption d-none d-md-block">
                        <h5>{item.caption}</h5>
                      </div>
                    </CarouselItem>
                  );
                })}
                <a
                  className="carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </a>
                <a
                  className="carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </a>
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
	          </Container>
      </div>
    </>
  );
}

export default CarouselSection;
