import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "react-auth0-spa";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              to="/Startseite"
			  tag={Link}
              id="navbar-brand"
            >
              Pfadfinder Haigerloch
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
			 {!isAuthenticated && (             <NavItem>
						  


                <Button
                  className="nav-link btn-neutral"
                  color="info"
					onClick={() => loginWithRedirect({})}

                  size="lg"
              tag={Link}
                >
                  <i className="now-ui-icons objects_key-25"></i>
                  <p>Login</p>
                </Button>      
              </NavItem>)}
			  			 {isAuthenticated && (             <NavItem>
                <Button
                  className="nav-link btn-neutral"
                  color="info"
					onClick={() => logout()}

                  size="lg"
              tag={Link}
                >
                  <i className="now-ui-icons objects_key-25"></i>
                  <p>Log out</p>
                </Button>      
              
              </NavItem>)}
              <NavItem>
                <NavLink to="/Startseite" tag={Link}>
				<i className="now-ui-icons location_world"></i>
				
                  Startseite
                </NavLink>
              </NavItem>
              <NavItem>
             <NavItem>
                <NavLink to="/Downloads" tag={Link}>
				
				<i className="now-ui-icons arrows-1_cloud-download-93"></i>
                  Download
                </NavLink>
              </NavItem>
              </NavItem>
			  <NavItem>
             {isAuthenticated && (<NavItem>
                <NavLink to="/Bilder" tag={Link}>
				
				<i className="now-ui-icons media-1_camera-compact"></i>
                  Bilder
                </NavLink>
              </NavItem>)}
              </NavItem>
              <NavItem> 
                <NavLink
                  href="https://www.facebook.com/pfadfinderhaigerloch"
                  target="_blank"
                  id="facebook-tooltip"
                >
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Like uns bei Facebook
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.instagram.com/pfadfinder.haigerloch/"
                  target="_blank"
                  id="instagram-tooltip"
                >
                  <i className="fab fa-instagram"></i>
                  <p className="d-lg-none d-xl-none">Instagram</p>
                </NavLink>
                <UncontrolledTooltip target="#instagram-tooltip">
                  Folge uns auf Instagram
                </UncontrolledTooltip>
              </NavItem>
			    {isAuthenticated && (<NavItem>
                <NavLink
                  to="/Profil" tag={Link}
                  id="Einstellungen-tooltip"
                >
                  <i class="fas fa-user-cog"></i>
                  <p className="d-lg-none d-xl-none">Einstellungen</p>
                </NavLink>
                <UncontrolledTooltip target="#Einstellungen-tooltip">
                  Profil Info
                </UncontrolledTooltip>
              </NavItem>)}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
