// src/App.js
import React from "react";
import { Link } from "react-router-dom";


// reactstrap components
// import {
// } from "reactstrap";

// core components

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import { Router, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import Profil from "components/Profile";
import Downloads from "views/examples/DownloadPage";
import Impressum from "views/examples/Impressum";
import Startseite from "views/examples/Startseite";
import Bilder from "views/examples/Bilder";
import history from "utils/history";
import PrivateRoute from "components/PrivateRoute";
import Rückschau_2019 from "assets/video/Rückschau 2019_comp.mp4";


function Index() {

  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
	 
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
	 
    };
  });
  return (
 <>
	<Router  history={history} >


      <IndexNavbar />
      <div className="wrapper">
		<Switch>
		<Route path="/Startseite/" component={Startseite} />
		<Route path="/Impressum/" component={Impressum} />
        <Route path="/Downloads/" component={Downloads} />
		<PrivateRoute path="/Profil/" component={Profil} />
		<PrivateRoute path="/Bilder/" component={Bilder} />
		<PrivateRoute path="/upload/" />
		<Redirect from="*" to="/Startseite/" />
		   
        </Switch>
		<DarkFooter />
      </div>
	  </Router >
    </>
  );
}


export default Index;