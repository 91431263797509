import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import Carousel from "views/index-sections/Carousel.js";

function LandingPage() {
	
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <div className="wrapper">

        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <h2 className="title">Pfadfinden</h2>
                <h5>


				...besteht nicht nur aus den allseits bekannten Klischees. „Nein, wir verkaufen keine Kekse!” Bei uns wird viel gespielt, gelacht und geredet, aber natürlich kommen auch die typischen Pfadfinderfertigkeiten nicht zu kurz. Wir üben Knoten, lernen mit einem Kompass umzugehen und versuchen auf die abenteuerlichsten Arten ein Feuer anzubekommen...
				</h5>
				<h5>
				Zu den Highlights in jedem Jahr gehört unser Zeltlager bei dem der ganze Stamm irgendwo sein Lager aufschlägt und die Natur pur erlebt. Im Herbst fahren wir regelmäßig für ein Wochenende auf eine Hütte. Wenn wir Hajken gehen, werden wir drei Tage lang ohne Zelt und Geld gemeinsam zu Fuß unterwegs zu sein.
</h5><h5>
				Na? Lust bekommen, mal zu schauen, was unsere Gruppen so machen? Oder vielleicht als Leiter einzusteigen? Einfach mit uns in Verbindung setzen oder bei einer unserer Gruppenstunden vorbeikommen.
                </h5>
              </Col>
			  <Col className="ml-auto mr-auto text-center" md="12">
			  <Carousel />
			  </Col>
            </Row>
			</Container>
            <div className="separator separator-primary"></div>
			<div className="section section-tabs">

			<Container>
            <div className="section-story-overview">
			
              <Row>
                <Col md="5">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg3.jpg") + ")"
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      "Versucht, die Welt ein bisschen besser zurückzulassen als Ihr sie vorgefunden habt." <br></br>
                      <br></br>
                      <small>-B.P.</small>
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg12.jpg") + ")"
                    }}
                  ></div>
                </Col>
                <Col md="7">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg1.jpg") + ")"
                    }}
                  ></div>
                  <h3>
                    Die Geschichte unsere Stamms Leonardo Da Vinci
                  </h3>
                  <p>
                    Im Jahre 1989 sammelte der damalige Stadtpfarrer Willi Kirchmann eine Anzahl Jugendlicher um sich, um mit Hilfe der Pfadfinder aus Heiligenzimmern auch in Haigerloch eine Pfadfindergruppe aufzubauen. Nachdem vier Jugendliche (Claus Gulde, Oliver Bolz, Volker Schmid und Michael Kuner) erste Erfahrungen in den Kursen und anderen Veranstaltungen des Bezirks gesammelt hatten, begannen in Haigerloch die pfadfinderischen Aktivitäten in Form einer 10 köpfigen Jupfigruppe.
                  </p>
                  <p>
                    So wurden in den darauffolgenden 3-4 Jahren die Voraussetzungen dazu geschaffen Haigerloch 1993 als eigenständige Siedlung anzuerkennen. Durch regen Mitglieder- und Leiterzuwachs konnte 1995 der Stamm Leonardo da Vinci gegründet werden. Um dieses Ereignis auch mit allen Jugendlichen in Haigerloch zu feiern organisierten wir das erste haigerlocher Pfadirock. Ebenfalls im Stammesgründungsjahr fand unser erstes Sommerlager im Bayrischen Wald statt.
                  </p>
                  <p>
                    Seitdem begibt sich der Stamm alle zwei Jahre auf große Fahrt. Neben diesen beiden großen Aktionen gibt es noch jede Menge kleinere im Stammesjahr, z.B. Weihnachtsbaumaktion, Hüttenwochenenden, Waldweihnacht, Gestaltung des Fronleichnamteppichs u.v.m.
                  </p>
                </Col>
              </Row>
            </div></Container></div>
          
        </div>
        <div className="section section-team text-center">
          <Container>
            <h2 className="title">Kontaktier uns persönlich...</h2>
            <div className="team">
              <Row>
                <Col md="3">
                  <div className="team-player">
				  <a href="https://maps.google.com/maps?cid=1484818891877175949" target="_blank">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/avatar.jpg")}
                    ></img>
                    <h4 className="title">Ort?</h4>
                    <p className="category text-info">BAUMÄCKERWEG 1, 72401 HAIGERLOCH</p>
					</a>
                  </div>
                </Col>
                <Col md="3">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/ryan.jpg")}
                    ></img>
                    <h4 className="title">Zeit?</h4>
                    <p className="category text-info">17:30-19:30</p>
                  </div>
                </Col>
                <Col md="3">
                  <div className="team-player">
				  <a href="https://calendar.google.com/calendar/embed?src=pfadfinderhaigerloch%40gmail.com&ctz=Europe%2FBerlin" target="_blank">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/eva.jpg")}
                    ></img>
                    <h4 className="title">Datum?</h4>
                    <p className="category text-info">KLICK MICH UM DEN KALENDER ZU ÖFFNEN</p>
					</a>
                  </div>
                </Col>
				<Col md="3">

                  <div className="team-player">
				  <a href="https://Pfadis-Haigerloch.de/Downloads/Anmeldebogen_Stamm_2020.pdf" target="_blank">
                    <img  alt="..." className="rounded-circle img-fluid img-raised" src={require("assets/img/Lilie.jpg")}></img>
                    <h4 className="title">Anmelden!</h4>
                    <p className="category text-info">KLICK MICH UM DIE ANMELDUNG HERUNTERZULADEN</p>
					</a>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
       
      </div>
    </>
  );
}

export default LandingPage;
